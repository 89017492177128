import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import { ApplicationForm } from '../../components/job'

const JobApplicationFormPage = ({ data }) => {
  const job = data.strapiJob
  return (
    <Layout tocEnabled={true} objectInfo={job}>
      <ApplicationForm job={job} />
    </Layout>
  )
}

export const jobApplicationFormPageQuery = graphql`
  query JobApplicationFormQuery($id: Int) {
    strapiJob(strapi_id: { eq: $id }) {
      __typename
      strapi_id
      updatedAt
      title
      slug
      position
      inspires
      deadline
      open
    }
  }
`

export default JobApplicationFormPage
